import * as React from 'react'
import { Provider } from 'mobx-react'
import { configure } from 'mobx'
import { Location } from '@reach/router'

import App from 'src/components/App'
import Ui from 'src/stores/ui'

const stores = {
  ui: new Ui()
}

configure({
  enforceActions: 'always', // enforces @action to update mobx states
})

const Wrapper = ({ element }: { element: any }): JSX.Element => (
  <Provider {...stores}>
    <Location>
      {({ location }) => <App location={location} content={element} />}
    </Location>
  </Provider>
)

export default Wrapper
