import * as React from 'react'
import isNode from 'is-node'
import { inject, observer } from 'mobx-react'
import { autorun } from 'mobx'
import { autobind } from 'core-decorators'
import { Helmet } from 'react-helmet'

type Props = {
  content: any
  ui?: any
  location: any
}

@inject('ui')
@observer
export default class App extends React.Component<Props> {
  disposer: any

  constructor(props: Props) {
    super(props)
    const { ui } = props

    this.disposer = autorun(() => {
      if (!isNode) {
        if (ui && ui.activeMenu) {
          document.body.classList.add('prevent-overflow')
        } else {
          document.body.classList.remove('prevent-overflow')
        }
      }
    })
  }

  componentDidMount(): void {
    const { ui, location } = this.props
    ui!.setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    })
    window.addEventListener('resize', this.onResize)
  }

  @autobind
  onResize(): void {
    const { ui } = this.props
    ui!.setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }

  render(): JSX.Element {
    const { content } = this.props

    return (
      <div className="app-wrapper">
        {content}
      </div>
    )
  }
}
