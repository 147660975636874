import { observable, action } from 'mobx'
import isNode from 'is-node'

export default class Ui {
  @observable windowHeight: number = 0
  @observable windowWidth: number = 0
  @observable headerHeight: number = 0
  @observable activeMenu: string = ''
  @observable webpChecked: boolean = false
  @observable webpAccepted: boolean = false

  constructor() {
    if (!isNode) {
      this.webpFeatureCheck('lossy', (feature: any, result: boolean) => {
        if (result) {
          this.setWebAccepted()
        } else {
          this.setWebNotAccepted()
        }
      })
    }
  }

  @action.bound
  setWebAccepted = () => {
    this.webpAccepted = true
    this.webpChecked = true
  }

  @action.bound
  setWebNotAccepted = () => {
    this.webpAccepted = false
    this.webpChecked = true
  }

  @action.bound
  webpFeatureCheck = (feature: any, callback: any) => {
    var kTestImages = {
      lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
      lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
      alpha:
        'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
      animation:
        'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
    }
    var img = new Image()
    img.onload = function() {
      var result = img.width > 0 && img.height > 0
      callback(feature, result)
    }
    img.onerror = function() {
      callback(feature, false)
    }
    // @ts-ignore
    img.src = 'data:image/webp;base64,' + kTestImages[feature]
  }

  @action.bound
  setWindowSize({ width, height }: { width: number; height: number }) {
    this.windowWidth = width
    this.windowHeight = height
  }

  @action.bound
  setHeaderHeight(height: number) {
    this.headerHeight = height
  }

  @action.bound
  setActiveMenu(selected: string) {
    this.activeMenu = selected
  }
}